import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AccountPlanAnalitica, AccountPlanSintetica } from '../data/account-plan';
import { AccountPlanAnaliticaData } from '../data/account-plan';
import { AccountPlanSinteticaData } from '../data/account-plan';
import { Observable } from 'rxjs';

@Injectable()
export class AccountPlanService extends AccountPlanAnaliticaData implements AccountPlanSinteticaData {
  private baseUrl = environment.services.financial.baseUrl;

  constructor(private http: HttpClient) {
    super();
  }

  list(): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/account_structure/tree/`)
  }

  // Implementação do método insert para AccountPlanAnalitica
  insertAnalitica(accountPlanAnalitica: AccountPlanAnalitica): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/account_structure/analytical/`, accountPlanAnalitica);
  }

  // Implementação do método insert para AccountPlanSintetica
  insertSintetica(accountPlanSintetica: AccountPlanSintetica): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/account_structure/synthetic/`, accountPlanSintetica);
  }

  get(id) {
    var result = this.http.get(`${this.baseUrl}/api/account_structure/analytical/${id}/`,id)
    return result;
  }

  getSintetica(id) {
    var result = this.http.get(`${this.baseUrl}/api/account_structure/synthetic/${id}/`,id)
    return result;
  }
}
